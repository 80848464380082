// export const config = {
//   apiUrl: "https://app.nvam.co",
//   altImg: "images/no_avatar_profile.jpg",
//   baseUrl: "https://app.nvam.co/",
//   altInvImg: "images/inv_img.jpeg",
//   mainUrl: "https://nvam.co",
//   facebookAppId: "276876873001354",
//   googleAppId:
//     "578420642265-thal45rhm91d9k52hohukaiqqn092pcg.apps.googleusercontent.com",
//   _api: path => {
//     return config.apiUrl + "/api/" + path;
//   },
//   dsn: "https://870d683c32034afdbb3d6d6ce9fddc16@o432627.ingest.sentry.io/5680197"
// };

export const config = {
  apiUrl: "https://nvam.us", // development
  altImg: "images/no_avatar_profile.jpg",
  baseUrl: "https://nvam.us/",
  altInvImg: "images/inv_img.jpeg",
  mainUrl: "https://nvam.us",
  appUrl: "https://about-trees.nvam.us",
  facebookAppId: "",
  googleAppId: "",
  _api: (path) => {
    return config.apiUrl + "/api/" + path;
  },
  // dsn:
  //   "https://c054a843149a4bb78c8c63e54507fb38@o432627.ingest.sentry.io/5680194",
  dsn: "https://870d683c32034afdbb3d6d6ce9fddc16@o432627.ingest.sentry.io/5680197" 
};
